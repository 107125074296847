<script setup>
import { ref } from 'vue';
import useUtilsFunc from "@/composables/useUtilsFunc.js";
import axios from 'axios';

const props = defineProps({
    from: {
        type: String,
        default: 'post'
    },
    data: {
        type: Object,
        required: true
    },
    id: { required: true }
    
});

const { handleFormError } = useUtilsFunc();
const { id, from } = props;
const loading = ref(false);
const form = ref({
    message: '',
    contract_amount: ''
});

const handleSubmit = async () => {
    try {
        loading.value = true;
        const { data } = await axios.post(`/legal/${id}/support`, { ...form.value, from });
        form.value.message = form.value.contract_amount = '';
        flashSwal(data.message, 'Thank You');
        let openedCanvas = bootstrap.Offcanvas.getInstance(document.getElementById('legalSupportOffcanvas'));
        openedCanvas.hide();
    } catch(error) {
        handleFormError(error.response);
    }
    loading.value = false;
}
</script>

<template>
    <slot name="supportIcon">
        <div class="buy-now">
            <a href="#" 
                data-bs-toggle="offcanvas" 
                data-bs-target="#legalSupportOffcanvas" 
                aria-controls="legalSupportOffcanvas" 
                class="btn-buy-now hover:shadow-sm p-1 shadow-lg rounded-circle"
            >
                <img src="/assets/img/legal-service.png" 
                    data-bs-toggle="tooltip" 
                    data-bs-offset="0,4" 
                    title="Legal Support" 
                    alt="Legal Support" />
            </a>
        </div>
    </slot>

    <div class="offcanvas offcanvas-end legal-support" tabindex="-1" id="legalSupportOffcanvas" aria-labelledby="legalSupportOffcanvasLabel">
        <div class="offcanvas-header">
            <h5 id="legalSupportOffcanvasLabel" class="offcanvas-title">Legal Support</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body mx-0 flex-grow-0">     
            <VForm @submit="handleSubmit">
                <div class="row gap-md-0 gap-3 mb-3">
                    <div class="col-md-12">
                        <p class="text-body text-justify">
                            Mills Oakley is the trusted law firm partner of Sharebridge. Mills Oakley, has a genuine understanding of not-for-profit organisations and the increasingly complex legislative issues surrounding them. Mills Oakley provides legal education, advice and specialist legal counsel to some of Australia’s leading not-for-profit organisations and social enterprises including charities, religious organisations, community groups and professional associations and is recognised by Chambers and Partners as a band one firm for charity law.
                        </p>
                    </div>

                    <div class="col-md-12 mt-2">
                        <p class="text-body mb-0 text-justify">
                            You can connect with Mills Oakley direct through this form and one of their specialist not-for-profit and charity experts will make direct contact with you.
                        </p>
                    </div>

                    <div class="col-md-12 mt-4">
                        <div class="text-body fw-semibold mb-2">Message: </div>                    
                        <VField as="textarea"
                            v-model.trim="form.message"
                            name="message"
                            rules="required|min:20" 
                            class="form-control" 
                            cols="30" 
                            rows="5" 
                            placeholder="Enter your message..."
                            v-autosize
                        />

                        <ErrorMessage class="invalid-feedback d-block" name="message" />
                    </div>
                </div>

                <p class="alert alert-warning" v-show="!data.canLegalSupport">
                    Your already requested for legal support. We shortly contact with you.
                    Thank You!
                </p>

                <div class="d-flex align-items-baseline">
                    <button type="submit" 
                        :class="$setLoadingSpinner(loading)" 
                        :disabled="loading || !data.canLegalSupport" 
                        class="btn btn-primary mb-2 d-grid w-100 mt-4"
                    >
                        Contact Mills Oakley
                    </button>
                    <button type="button" class="btn btn-label-secondary d-grid w-100 ms-3" data-bs-dismiss="offcanvas">Cancel</button>
                </div>
            </VForm>
        </div>
    </div>
</template>